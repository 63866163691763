import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CampaignList from './components/CampaignList';
import EmptyCampaignsPlaceholder from './components/EmptyCampaignsPlaceholder';

import { Depot } from 'models';
import { apiGamification } from 'modules';
import { useCallbackAsync, useHeadTitle } from 'hooks';
import { UserContext } from 'store';

const Campaigns: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { communityId } = useContext(UserContext);

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const [campaigns, setCampaigns] = useState<Depot[]>();

  useHeadTitle(
    `${t('group_settings')} - ${t('global-gamification')} - ${t(
      'global-campaigns'
    )}`
  );

  const breadcrumbsLinks = useMemo(
    () => [
      {
        id: 'gamification-link',
        text: t('global-gamification'),
        href: `/community/${organisationId}/admin/gamification`,
      },
      {
        id: 'points-link',
        text: t('global-campaigns'),
        href: `/community/${organisationId}/admin/gamification/campaigns`,
      },
    ],
    [t, organisationId]
  );

  const [loadCampaigns] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-page',
    deps: [communityId],
    callback: async () => {
      const {
        data: {
          data: { depots },
        },
      } = await apiGamification.getOrganisationDepots(
        +organisationId,
        'gamification'
      );
      if (depots) {
        setCampaigns(depots);
      }
    },
  });

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns, communityId]);

  return (
    <Fragment>
      <Breadcrumbs
        tabIndex={0}
        aria-label="breadcrumbs"
        crumbs={breadcrumbsLinks}
      />
      {campaigns?.length === 0 ? (
        <EmptyCampaignsPlaceholder />
      ) : (
        <CampaignList campaigns={campaigns} onUpdateCampaigns={loadCampaigns} />
      )}
    </Fragment>
  );
};

export default Campaigns;
