import { FC, useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import { appUri } from '../../../../config';
import SurveyContext from '../../providers/Survey/Survey.context';
import SurveyPageWrapper from '../../components/SurveyPageWrapper';

import { Button } from '@faxi/web-component-library';

const Initial: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { activeSurvey } = useContext(SurveyContext);

  const location = useLocation();

  const handleStart = useCallback(() => {
    const navigationStep = `${location.search.substring(1)}/start/${
      appUri.SURVEY_START_COMMUTING_PATTERN
    }`;
    navigate(navigationStep, { replace: true });
  }, [location.search, navigate]);

  return (
    <SurveyPageWrapper
      className="kinto-survey-initial"
      title={activeSurvey?.name || ''}
      imageUrl="/assets/svg/survey_main_image.svg"
      subtitle={
        parse(
          t('survey-start_screen_note_survey_will_close_date', {
            enddate: dayjs(activeSurvey?.end_date).format('DD MMMM YYYY'),
          }).replace(/\\n/g, '\n')
        ) as string
      }
      paragraph={t('sustainability-body_introduction').replace(/\\n/g, '\n')}
    >
      <Button className="kinto-survey-main__start-btn" onClick={handleStart}>
        {t('start')}
      </Button>
    </SurveyPageWrapper>
  );
};

export default Initial;
