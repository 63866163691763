import React, { createContext, MutableRefObject } from 'react';
import { Survey } from 'models';
import dayjs from 'dayjs';

type SustainabilityContext = {
  surveys: Survey[];
  emptyPlaceholder: boolean;
  activeSurveyId: string;
  activeSurvey?: Survey;
  surveyReportRetry: MutableRefObject<Function>;
  disabledDates: dayjs.Dayjs[];
  setActiveSurveyId: (value: string) => void;
  updateSurvey: (surveyId: string, data: Partial<Survey>) => void;
  openModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  retrySurveys: (...args: any[]) => Promise<void>;
};

export default createContext<SustainabilityContext>({
  surveys: [],
  emptyPlaceholder: false,
  activeSurveyId: '',
  disabledDates: [],
  openModal: () => {},
  updateSurvey: () => {},
  setActiveSurveyId: () => {},
  surveyReportRetry: { current: () => {} },
  retrySurveys: () => new Promise(() => {}),
});
