import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { appUri } from '../../../../config';
import { useQuery } from '../../../../hooks';
import { BlockUI } from '../../../../helpers';
import { APIResponse } from '../../../../models';
import { SurveyInstance } from '../../../../models/Survey';

import SurveyContext, { SurveyContextProps } from './Survey.context';
import apiSurvey from '../../../../modules/api/apiSurvey';
import { ScrollToTop } from '../../../../components';

const INVALID_STATUSES = ['unknown', 'canceled'] as string[];

type SurveyProviderProps = PropsWithChildren;

const SurveyProvider: FC<SurveyProviderProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const { surveySlug } = useParams() as { surveySlug: string };

  const [yourEmission, setYourEmission] = useState<number>(0);

  const location = useLocation();

  const userSlag = useMemo(
    () => location.search.substring(1),
    [location.search]
  );

  const checkSurveySlag = useCallback(
    async () => await apiSurvey.checkSurveySlag(surveySlug, userSlag),
    [surveySlug, userSlag]
  );

  const { data: activeSurvey } = useQuery<
    APIResponse<SurveyInstance>,
    SurveyInstance
  >({
    showSpinner: true,
    queryFn: checkSurveySlag,
    condition: !!surveySlug,
    mappingFunction: async (res) => res.data,
    onSuccess: (data) => {
      if (INVALID_STATUSES.includes(data.status)) {
        navigate(`/${appUri.LOGIN}`);
        return;
      }

      if (data.status === 'active' && (data as SurveyInstance)?.code === 9008) {
        navigate(`/survey/${surveySlug}/${appUri.SURVEY_ALREADY_COMPLETED}`);
      }

      if (data.status === 'pending') {
        navigate(`/survey/${surveySlug}/${appUri.SURVEY_SCHEDULED}`);
        return;
      }

      if (data.status === 'finished') {
        navigate(`/survey/${surveySlug}/${appUri.SURVEY_FINISHED}`);
        return;
      }
    },
  });

  const blockUI = !activeSurvey && !!surveySlug;

  const providerValues = useMemo(
    () =>
      ({
        yourEmission,
        activeSurvey,
        setYourEmission,
      } as SurveyContextProps),
    [activeSurvey, yourEmission]
  );

  return (
    <SurveyContext.Provider value={providerValues}>
      <ScrollToTop targetNodeId="body" />

      <BlockUI condition={blockUI} fallback={<Fragment />}>
        {children}
      </BlockUI>
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
