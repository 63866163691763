import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from '../components/_modals/FiltersModal/FiltersModal.component';
import { capitalizeFirstLetter } from './basics';
import { JOURNEY_DIRECTIONS } from './reports';

export default function useJourneyFilter() {
  const { t } = useTranslation();

  const filters = useMemo<Filter[]>(
    () => [
      {
        legendLabel: t('mJourneys'),
        name: 'directions',
        checkboxes: [
          {
            value: 'to-office',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-office'])),
            id: 'filter_reports_to-office',
          },
          {
            value: 'to-home',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-home'])),
            id: 'filter_reports_to-home',
          },
          {
            value: 'to-event',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-event'])),
            id: 'filter_reports_to-event',
          },
          {
            value: 'to-custom',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-custom'])),
            id: 'filter_reports_to-custom',
          },
        ],
      },
    ],
    [t]
  );

  return filters;
}
