import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';
import { PageLayout } from '../../../../components';

export const CreateSurvey = styled(PageLayout)`
  form {
    ${flex('column')};
    max-width: ${theme.sizes.SIZE_944};
  }

  .create-survey {
    &__header {
      ${flex('row', 'space-between', 'center')};
      max-width: ${theme.sizes.SIZE_944};
    }
    &__survey-name {
      max-width: 50%;
    }

    &__duration {
      ${flex('column')};
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_16};
      border-radius: ${theme.sizes.SIZE_8};
      margin: ${theme.sizes.SIZE_16} 0;

      &__header {
        display: flex;
        margin-bottom: ${theme.sizes.SIZE_12};

        h2 {
          font-size: ${theme.fontSizes.FONT_18};
          color: var(--SECONDARY_1_1);
          margin-left: ${theme.sizes.SIZE_8};
          line-height: 1;
        }
      }

      &__fields {
        display: flex;
        gap: ${theme.sizes.SIZE_32};
        padding-left: ${theme.sizes.SIZE_24};
        flex-wrap: wrap;

        > div {
          min-width: 272px;
        }
      }
    }

    &__participants {
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_16};

      &__header {
        display: flex;
        margin-bottom: ${theme.sizes.SIZE_12};

        h2 {
          font-size: ${theme.fontSizes.FONT_18};
          color: var(--SECONDARY_1_1);
          margin-left: ${theme.sizes.SIZE_8};
          line-height: 1;
        }
      }

      &__content {
        padding-left: 28px;

        &__explanation {
          font-size: ${theme.fontSizes.FONT_14};
          color: var(--SHADE_1_1);

          strong {
            font-weight: 600;
          }
        }
      }

      &__upload-csv-btn {
        display: flex;
        gap: ${theme.sizes.SIZE_32};
        margin: ${theme.sizes.SIZE_16} 0;
      }
    }

    &__run-survey-btn {
      margin-top: ${theme.sizes.SIZE_24};
    }

    &__breadcrumbs {
      margin-top: ${theme.sizes.SIZE_48};
    }

    &__send-actions {
      display: flex;
      margin-top: ${theme.sizes.SIZE_24};
      gap: ${theme.sizes.SIZE_46};
    }
  }

  .kinto-page__heading__send-survey {
    ${fontSize(theme.fontSizes.FONT_22, theme.sizes.SIZE_32)};
    margin-top: ${theme.sizes.SIZE_20};
    color: var(--SECONDARY_1_1);
  }
`;
