import { useCallback, useContext, useMemo } from 'react';
import { useCallbackRef } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { StepsContext, StepsProvider, StepsProviderRef } from '@faxi/web-form';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { surveyFormSteps } from '../../routes';
import storageService, {
  STORAGE_KEYS,
} from '../../../../services/storageService';
import { appUri } from '../../../../config';
import { SurveyAnswers, SurveyForm } from '../../../../models/Survey';
import apiSurvey from '../../../../modules/api/apiSurvey';
import SurveyContext from '../../providers/Survey/Survey.context';
import { useCallbackAsync } from '../../../../hooks';
import SurveyPageWrapper from '../../components/SurveyPageWrapper';

const FORM_STEP_IMAGE = [
  'survey_woman_working',
  'survey_house',
  'survey_building',
  'survey_car_driving',
  'survey_pickup_passenger',
];

const StepElement = () => {
  const { t } = useTranslation();
  const { currentStep, steps } = useContext(StepsContext);

  return (
    <div className="kinto-survey-main__left-section__survey-form-step">
      {t('groupreg_step')} {currentStep}/{steps?.length}
    </div>
  );
};

const Form = () => {
  const navigate = useNavigate();

  const [steps, stepsRef] = useCallbackRef<StepsProviderRef>();

  const { surveySlug, userSlug } = useParams<{
    surveySlug: string;
    userSlug: string;
  }>();

  const { setYourEmission } = useContext(SurveyContext);

  const stepImageURL = useMemo(
    () => `/assets/svg/${FORM_STEP_IMAGE[steps?.currentStep - 1]}.svg`,
    [steps?.currentStep]
  );

  const clearFormStorage = useCallback(() => {
    storageService.removeItem(STORAGE_KEYS.FORM);
    storageService.removeItem(STORAGE_KEYS.COMPLETED_STEPS);
  }, []);

  const [handleStepsCompleted] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-survey',
    callback: async (values: SurveyForm) => {
      clearFormStorage();

      const {
        bus,
        carpooling,
        driving,
        cycling,
        walking,
        train,
        corporate_shuttle,
        engine_type,
        year,
      } = values;

      const surveyAnswers = {
        'survey-slug': surveySlug,
        'user-slug': userSlug,
        answers: {
          q1: values['commuting-pattern'],
          q2: values['home-address'],
          q3: values['destination-address'],
          q4: {
            bus,
            carpooling,
            driving,
            cycling,
            walking,
            train,
            corporate_shuttle,
          },
          q5: {
            engine_type,
            year,
          },
        },
      } as SurveyAnswers;

      const { data } = await apiSurvey.answerSurvey(surveyAnswers);

      setYourEmission(data.data.co2);

      navigate({
        pathname: `/survey/${surveySlug}/${appUri.SURVEY_YOUR_EMISSIONS}`,
        search: `co2=${data.data.co2}`,
      });
    },
  });

  return (
    <SurveyPageWrapper className="kinto-survey-form" imageUrl={stepImageURL}>
      <StepsProvider
        ref={stepsRef}
        steps={surveyFormSteps}
        formStorageKey={STORAGE_KEYS.FORM}
        formStepsCompletedStorageKey={STORAGE_KEYS.COMPLETED_STEPS}
        onStepsCompleted={handleStepsCompleted as any}
      >
        <StepElement />
        <Outlet />
      </StepsProvider>
    </SurveyPageWrapper>
  );
};

export default Form;
