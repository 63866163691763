import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import { EmptyTab } from 'components';
import SurveysBoard from '../../components/SurveysBoard';
import SustainabilityContext from '../../providers/Sustainability/Sustainability.context';

import * as Styled from './Sustainability.styles';
import { getColor, Heading } from '@faxi/web-component-library';
import classNames from 'classnames';

const Sustainability: FC = () => {
  const { t } = useTranslation();
  const { emptyPlaceholder } = useContext(SustainabilityContext);

  const navigate = useNavigate();

  const emptyTabTitle = parse(
    t('sustainability-title_run_survey').replace(/\\n/g, '<br />')
  );

  return (
    <Styled.Sustainability
      className={classNames('kinto-page', 'sustainability')}
    >
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('global-sustainability')}
      </Heading>

      {emptyPlaceholder ? (
        <div className="sustainability__content">
          <EmptyTab
            icon="leaf"
            title={emptyTabTitle}
            actionTitle={t('global-new_run_survey')}
            description={t('sustainability-subtitle_run_survey')}
            onAction={() => navigate('new-survey')}
          />
        </div>
      ) : (
        <SurveysBoard />
      )}
    </Styled.Sustainability>
  );
};

export default Sustainability;
