import { GoogleAutocompleteProps } from '@faxi/web-component-library';
import { ObjectValues } from './Generics';

export const SURVEY_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  FINISHED: 'finished',
  CANCELED: 'canceled',
} as const;
export type SurveyStatus = ObjectValues<typeof SURVEY_STATUS>;

export type Survey = {
  id: number;
  survey_id: number;
  organisation_id: number;
  name: string;
  start_date: string;
  start_time?: string;
  end_date: string;
  end_time?: string;
  participants_total: number;
  description: string;
  status: SurveyStatus;
  created_at: string;
  updated_at: string;
  organisation_name: string;
  formatted_address: string;
  additional_data: {
    organisation_status: string;
    'survey-slug': string;
  };
} & SurveyReportType;

export type TransportReport = { distance: number; users: number };

export type SurveyReportType = {
  average_distance: number;
  average_on_site: number;
  bus: TransportReport;
  carpooling: TransportReport;
  corporate_shuttle: TransportReport;
  cycling: TransportReport;
  driving: TransportReport;
  train: TransportReport;
  walking: TransportReport;
  hybrid_percent: number;
  on_site_percent: number;
  participants_finished: number;
  public_inputs_total: number;
  participants_total: number;
  newcomers_total: number;
  newcomers_finished: number;
  remote_percent: number;
  co2: number;
  surveyed_users_percentage: number;
  predicted_users_percentage: number;
};

export type SurveyAnalysis = {
  id: number;
  survey_instance_id: number;
  solver_response_id: string;
  // TODO: after be is finish add data type
  data: any;
  status: 'pending' | 'finished';
  created_at: string;
  updated_at: string;
};

export type SurveyCommutingType = 'remote' | 'hybrid' | 'on_site';
export type SurveyEngineType = 'diesel' | 'petrol' | 'electric' | 'hybrid';

export type SurveyTransportMode = {
  driving: number;
  carpooling: number;
  walking: number;
  cycling: number;
  bus: number;
  train: number;
  corporate_shuttle: number;
};

export type SurveyVehicleType = {
  engine_type: SurveyEngineType;
  year: number;
};

export type SurveyFromQuestions = {
  q1: SurveyCommutingType;
  q2: GoogleAutocompleteProps;
  q3: GoogleAutocompleteProps;
  q4: SurveyTransportMode;
  q5: SurveyVehicleType;
};

export type SurveyForm = {
  'commuting-pattern': SurveyCommutingType;
  'destination-address': GoogleAutocompleteProps;
  'home-address': GoogleAutocompleteProps;
} & SurveyTransportMode &
  SurveyVehicleType;

export type SurveyAnswers = {
  'survey-slug': string;
  'user-slug': string;
  email: string;
  answers: SurveyFromQuestions;
};

export type SurveyInstance = {
  name: string;
  status: 'active' | 'finished' | 'unknown' | 'pending';
  end_date: string;
  code?: number;
};
