import httpClient from '../httpClient';
import { LeaguetableParams, LeaguetableResponse, StatsByRange } from 'models';
import { AxiosRequestConfig } from 'axios';

//ca:1 query param which represent data relevant only for client admin
//e.g. ['user-approved', 'message', 'journey-HWpocs', 'journey-WHpocs', 'passenger-HWdist', 'passenger-WHdist']

const getKpi = async (
  startdate: string,
  enddate: string,
  oid: number,
  unit: string,
  directions: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .get<{
      rc: 'ok' | 'error';
      'date-range': Record<string, Record<string, number>>;
      'active-users-total': number;
      'active-users-total-test': number;
      'no-journeys-total': number;
      'no-journeys-total-test': number;
      'verified-journeys-total': number;
      'verified-journeys-total-test': number;
    }>(`admin/kpi`, {
      params: {
        startdate,
        enddate,
        oid,
        unit,
        directions,
        ca: 1,
      },
      ...config,
    })
    .then((res) => res.data);

export type LeaderboardGetParamType =
  | 'point'
  | 'all'
  | 'driving'
  | 'walking'
  | 'cycling'
  | 'passengers';

export type LeaderboardDirections =
  | 'to-home'
  | 'to-office'
  | 'to-event'
  | 'to-custom';

const leaguetableReport = async ({
  oid,
  from,
  to,
  unit,
  count,
  offset,
  search,
  directions,
  type,
  config,
  sort_by,
  sort_direction,
  period,
}: Omit<LeaguetableParams, 'count'> & { count?: number }) =>
  httpClient
    .get<LeaguetableResponse>(`admin/leaguetable`, {
      params: {
        oid,
        from,
        to,
        unit,
        count,
        offset,
        search,
        ...(directions && { directions }),
        type,
        sorted: true,
        sort_by,
        sort_direction,
        period,
      },
      ...config,
    })
    .then((res) => res.data);

const statsByRangeReport = async (
  oid: number,
  startdate: string,
  enddate: string,
  unit: string,
  directions?: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .get<{ rc: 'ok' | 'error'; stats: StatsByRange }>('admin/statbyrange', {
      params: {
        oid,
        startdate,
        enddate,
        unit,
        ...(directions && { directions }),
        ca: 1,
      },
      ...config,
    })
    .then((res) => res.data);

export default {
  getKpi,
  leaguetableReport,
  statsByRangeReport,
};
