import { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import {
  config,
  ModalProps,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'store';
import { dateStringLocale } from 'utils';
import { useCallbackAsync } from 'hooks';
import { Survey } from 'models';
import { getDatesBetween, validateEndDate } from '../../utils';
import { CalendarField, InputField } from 'components';
import apiSurvey from 'modules/api/apiSurvey';
import FormFooter from 'components/_forms/FormFooter';

import * as Styles from './SurveyModal.styles';

export type SurveyFormType = Pick<Survey, 'name' | 'start_date' | 'end_date'>;

type SurveyModalProps = Partial<ModalProps> & {
  oid?: number;
  activeSurvey?: Survey;
  disabledDates?: Dayjs[];
  onSurveyRun: (survey: Survey, isEdit: boolean) => void;
};

const SurveyModal: FC<SurveyModalProps> = (props) => {
  const {
    oid: organisation_id,
    disabledDates: pDisabledDates,
    activeSurvey,
    onSurveyRun,
    ...rest
  } = props;

  const { t } = useTranslation();
  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const { showSnackBar } = useUtilities();
  const { userPreferences } = useContext(UserContext);

  const initialData = useMemo(
    () =>
      activeSurvey !== undefined
        ? {
            name: activeSurvey.name,
            start_date: dayjs(
              dateStringLocale(
                activeSurvey?.start_date,
                activeSurvey.start_time,
                config.dateFormat
              ),
              config.dateFormat
            ),
            end_date: dayjs(
              dateStringLocale(
                activeSurvey?.end_date,
                activeSurvey.end_time,
                config.dateFormat
              ),
              config.dateFormat
            ),
          }
        : undefined,
    [activeSurvey]
  );

  const isEditing = useMemo(() => initialData !== undefined, [initialData]);

  const disabledDates = useMemo(
    () =>
      isEditing
        ? pDisabledDates?.filter(
            (d: Dayjs) =>
              !getDatesBetween(
                dayjs(initialData!.start_date),
                dayjs(initialData!.end_date)
              ).find((date) => date.isSame(d))
          )
        : pDisabledDates,
    [isEditing, pDisabledDates, initialData]
  );

  const end_date = useFormRefValues(form, 'end_date')?.end_date;
  const start_date = useFormRefValues(form, 'start_date')?.start_date;

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('name') })
        ),
        validators.general.maxLength(
          150,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '150',
          })
        ),
      ],
      start_date: [validators.general.required(t('required_start_date'))],
      end_date: [
        validators.general.required(t('required_end_date')),
        validateEndDate(
          t('survey-range_cant_choose_range'),
          disabledDates as Dayjs[]
        ),
      ],
    }),
    [t, disabledDates]
  );

  const [handleSubmitSurvey, loadingSurvey] = useCallbackAsync({
    showSpinner: false,
    callback: async (data: SurveyFormType) => {
      const requestData = {
        ...data,
        organisation_id,
        start_date: dayjs(data.start_date).format(config.apiDateFormat),
        end_date: dayjs(data.end_date).format(config.apiDateFormat),
      } as Survey;

      const { data: newSurvey } = isEditing
        ? await apiSurvey.editSurvey(`${activeSurvey!.id}`, requestData)
        : await apiSurvey.createSurvey(requestData);

      showSnackBar({
        variant: 'success',
        actionButtonText: t('dismiss'),
        text: t(
          isEditing
            ? `sustainability-toast_survey_updated`
            : `sustainability-toast_survey_created`
        ),
      });

      onSurveyRun?.(newSurvey, isEditing);
    },
  });

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        ref={formRef}
        children={children}
        className={className}
        initialData={initialData}
        onSubmit={handleSubmitSurvey}
      />
    ),
    [formRef, initialData, handleSubmitSurvey]
  );

  return (
    <Styles.SurveyModal
      ref={modalRef}
      className="kinto-survey-modal"
      subtitle={t('sustainability-modal_subtitle')}
      title={t(
        isEditing
          ? 'sustainability-button_edit_survey'
          : 'global-new_run_survey'
      )}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t(`global-new_run_survey`),
      })}
      loading={loadingSurvey}
      childrenWrapper={modalForm}
      footer={
        <FormFooter
          modalRef={modalRef}
          cancelLabel={t('cancel')}
          className="kinto-survey-modal__footer"
          submitLabel={
            !isEditing
              ? t('global-run_survey')
              : t('sustainability-button_update_survey')
          }
        />
      }
      {...rest}
    >
      <fieldset>
        <legend data-hidden>{t('global-name')}</legend>
        <FormField
          required
          name="name"
          component={InputField}
          autoComplete="off"
          className="kinto-survey-modal__name"
          placeholder={t('global-name')}
          validate={validations.name}
        />
      </fieldset>

      <fieldset>
        <legend className="calendar-labels">{t('start')}</legend>
        <FormField
          required
          name="start_date"
          renderAsPortal
          component={CalendarField}
          leftLimitDate={dayjs()}
          rightLimitDate={end_date || dayjs().add(2, 'years')}
          disabledDates={disabledDates}
          className="kinto-survey-modal__start-date"
          placeholder={t('campaign-start_date')}
          dateFormat={userPreferences.dateFormat}
          validate={validations.start_date}
          disabled={activeSurvey?.status === 'active'}
        />
      </fieldset>

      <fieldset>
        <legend className="calendar-labels">{t('end')}</legend>
        <FormField
          required
          name="end_date"
          renderAsPortal
          component={CalendarField}
          leftLimitDate={start_date || dayjs()}
          rightLimitDate={dayjs().add(2, 'years')}
          disabledDates={disabledDates}
          className="kinto-survey-modal__end-date"
          placeholder={t('campaign-end_date')}
          dateFormat={userPreferences.dateFormat}
          validate={validations.end_date}
        />
      </fieldset>
    </Styles.SurveyModal>
  );
};

export default memo(SurveyModal);
