import { SurveyReport, Sustainability } from 'pages/Sustainability/pages';
import { ExtendRouteObject } from 'router/routes';
import { appUri } from '../../../../../config';
import CreateSurvey from '../../../../../pages/Sustainability/pages/CreateSurvey';

export default [
  {
    path: '',
    element: <Sustainability />,
  },
  {
    path: appUri.SUSTAINABILITY_SURVEY,
    element: <SurveyReport />,
  },
  {
    path: appUri.SUSTAINABILITY_NEW_SURVEY,
    element: <CreateSurvey />,
  },
  {
    path: appUri.SUSTAINABILITY_SEND_SURVEY,
    element: <CreateSurvey />,
  },
] as ExtendRouteObject[];
