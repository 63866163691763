import React, { Fragment, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Icon, Input } from '@faxi/web-component-library';

import * as ModalsStyled from '../../../../../components/_modals/EmailListModal/EmailListModal.styles';

type EmailsModalProps = {
  emailList: string[];
  onClose: () => void;
  onRemoveEmail: (email: string) => void;
};

const EmailsModal = (props: EmailsModalProps) => {
  const { emailList, onClose, onRemoveEmail } = props;

  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [emailListShown, setEmailListShown] = useState(emailList);

  const handleRemoveEmail = useCallback(
    (email: string) => {
      onRemoveEmail(email);
    },
    [onRemoveEmail]
  );

  useEffect(() => {
    setEmailListShown(emailList.filter((email) => email.includes(search)));
  }, [emailList, search]);

  return (
    <ModalsStyled.EmailListModal
      className="email-list-modal"
      onClose={() => onClose()}
      title={t('preapproved_emails')}
    >
      <div
        className={classNames(
          'email-list-modal__body',
          'email-list-modal__body--padded-right'
        )}
      >
        <Input
          placeholder={t('preapproved_settings-placeholder_search_an_email')}
          prefixIcon={<Icon name="magnifying-glass" />}
          value={search}
          onChange={setSearch}
          className="email-list-modal__search"
        />
        {emailListShown?.map((email, index) => (
          <Fragment key={`email_${index}`}>
            <div className="email-list-modal__body__row">
              <div className={classNames('email-list-modal__body__row__email')}>
                {email}
              </div>
              <Button
                icon={<Icon name="xmark" />}
                aria-label={t('accessibility-button_delete', {
                  name: email,
                })}
                className="email-list-modal__body__row__remove"
                variant="ghost"
                onClick={() => handleRemoveEmail(email)}
              />
            </div>
            {index < emailList.length - 1 && <Divider />}
          </Fragment>
        ))}
      </div>
    </ModalsStyled.EmailListModal>
  );
};

export default EmailsModal;
