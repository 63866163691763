import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SurveyContext from '../../providers/Survey/Survey.context';
import SurveyPageWrapper from '../../components/SurveyPageWrapper';
import { appUri } from '../../../../config';

const AlreadyCompleted: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { activeSurvey } = useContext(SurveyContext);

  return (
    <SurveyPageWrapper
      title={activeSurvey?.name || ''}
      subtitle={t('sustainability-web_survey-emal_used-main')}
      imageUrl="/assets/svg/survey_woman_autumn.svg"
      buttonLabel={t('go_to_kinto_join_app')}
      onAction={() => navigate(`/${appUri.LOGIN}`)}
    />
  );
};

export default memo(AlreadyCompleted);
