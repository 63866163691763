import { createContext } from 'react';
import { ChartDataTotals, ChartDataWithTest, KpiData } from 'models';
import { DatePickerValue } from '@faxi/web-component-library';

interface ReportsContext {
  dateRange?: DatePickerValue;
  rangeStats?: {
    messagesCount: ChartDataWithTest;
    registeredUsers: ChartDataWithTest;
  };
  rangeStatsReportError: boolean;
  kpiData?: KpiData;

  activeUsersTotal: ChartDataTotals;

  distancesReportError: boolean;

  loadingDistances: boolean;

  statsByRangeReport: (
    organisationId: number,
    startDate: string,
    endDate: string,
    unit: string,
    directions: string
  ) => void;
  updateDateRange: (range: DatePickerValue) => void;
  applyDefaultDateParams: () => void;
  convertReportsDataToArrays: () => Promise<string[][] | undefined>;

  numberOfMessagesTotal: number;
  numberOfTestMessagesTotal: number;
}

export const reportsDefaultValue: ReportsContext = {
  rangeStats: undefined,
  rangeStatsReportError: false,
  distancesReportError: false,
  loadingDistances: false,

  statsByRangeReport: () => {},
  updateDateRange: () => {},
  applyDefaultDateParams: () => {},
  convertReportsDataToArrays: () => new Promise(() => {}),

  activeUsersTotal: { total: 0, testTotal: 0 },

  numberOfMessagesTotal: 0,
  numberOfTestMessagesTotal: 0,
};

const ReportsContext = createContext<ReportsContext>(reportsDefaultValue);

export default ReportsContext;
