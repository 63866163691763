import { theme } from '@faxi/web-component-library';
import { flex, phablet, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';
export const EmailRequestSentStyled = styled.div`
  ${flex('column', undefined, 'center')}
  width: ${theme.sizes.SIZE_504};
  margin: auto;
  .resend-email {
    &__check-icon {
      svg {
        ${size('54px')};
        color: var(--PRIMARY_1_1);
      }
    }
    &__explanation {
      text-align: center;
      color: var(--SHADE_1_2);
    }
    &__email {
      display: flex;
      color: var(--PRIMARY_1_1);
      margin: ${theme.sizes.SIZE_16} 0 ${theme.sizes.SIZE_32};
      svg {
        color: var(--PRIMARY_1_1);
        margin-left: ${theme.sizes.SIZE_16};
        margin-top: ${theme.sizes.SIZE_2};
      }
    }
  }
  h1 {
    font-size: ${theme.sizes.SIZE_24};
    font-weight: 600;
    margin-bottom: 0;
  }
  button {
    min-width: ${theme.sizes.SIZE_320};
  }
  ${phablet(css`
    width: 100%;
    padding-top: ${theme.sizes.SIZE_32};
  `)}
`;
