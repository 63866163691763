import { FC, useContext, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { UserContext } from 'store';
import SustainabilityProvider from './providers/Sustainability';

const SustainabilityRouter: FC = () => {
  const { communityId, community, userReady } = useContext(UserContext);

  const navigate = useNavigate();

  const premiumFeatureAvailable = useMemo(
    () => !!community?.premium_features?.find(({ name }) => name === 'survey'),
    [community]
  );

  useEffect(() => {
    if (!premiumFeatureAvailable && communityId && userReady) {
      navigate(`community/${communityId}/home`);
    }
  }, [premiumFeatureAvailable, communityId, userReady, navigate]);

  return (
    <SustainabilityProvider>
      <Outlet />
    </SustainabilityProvider>
  );
};

export default SustainabilityRouter;
