import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';
import { PageLayout } from '../../../../components';

export const Sustainability = styled(PageLayout)`
  .sustainability {
    &__content {
      ${flex('column', 'center', 'center')};
      height: 100%;
    }
  }
  .empty-tab {
    margin-top: -${theme.sizes.SIZE_80};
  }
`;
