import { theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const EmptyTab = styled.div`
  ${flex('column', 'center', 'center')}

  width: 100%;
  padding: ${theme.sizes.SIZE_10} 0;
  max-width: ${pxToRem(theme.sizes.SIZE_488)};

  .empty-tab {
    &__icon {
      &,
      svg {
        ${size(theme.sizes.SIZE_64)};
      }

      color: var(--ACCENT_1_1);
      margin: 0 0 ${theme.sizes.SIZE_16};
    }

    &__title,
    &__content {
      margin: 0;
      text-align: center;
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_40)};

      font-weight: 600;
      margin: 0 0 ${theme.sizes.SIZE_12};
      color: var(--SECONDARY_1_1);
    }

    &__description {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 400;
      text-align: center;
      color: var(--SHADE_1_2);
      max-width: ${pxToRem(theme.sizes.SIZE_374)};
    }

    &__button {
      margin-top: ${theme.sizes.SIZE_16};
    }
  }
`;
